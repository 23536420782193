<template>
  <b-card class="login-body mb-5">
    <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.LOGIN_BANKID')}}</h3>
    <form>
      
      <div id="qr-container">
        <img style="margin:auto; width: 60%;" align-h="center" :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" class="img-fluid mb-8" alt="bankid" />

        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>

      </div>
     
      <div v-if="show_no_permissions" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
      </div>

      <div v-if="!member_bankid_order_ref" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_BANKID_DATA')}}</p></div>
      </div>
      
      
    </form>
  </b-card>
</template>

<style lang="scss" scoped>

#qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';

import axios from 'axios';

import { validationMixin } from 'vuelidate';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { MEMBER_LOGIN, MEMBER_LOGOUT, MEMBER_SET_COMPANY, MEMBER_SET_LOGINLINK } from '@/core/services/store/member.auth.module';

export default {
  mixins: [ validationMixin ],
  name: 'MemberLoginBankIdReturn',
  props: ['redirect'],
  data() {
    return {
      debug: false,
      show_no_permissions: false,
      member_bankid_order_ref: null,
    };
  },

  validations() {
    return {
      form: {
        personnr: {
          required: true,
          validate_personnr: validate_personnr,
          isPersonnrValid: isPersonnrValid
        }
      }
    };
  },
  
  async mounted() {

    const order_ref = this.$route.query.order_ref;

    this.debug = getKeyValue('debug');

    if (this.debug) {
      console.log('--- BANKID-RETURN DEBUG ---');
      console.log('order_ref', order_ref);
    }

    if (!order_ref || (order_ref+'').length < 10) {
      const stored_order_ref = getKeyValue('member_bankid_order_ref');
      console.log('using stored order ref', stored_order_ref);
      this.member_bankid_order_ref = stored_order_ref;
      this.simple_bankid_collect(stored_order_ref);
    }
    else {
      console.log('using query order ref', order_ref);
      this.member_bankid_order_ref = order_ref;
      this.simple_bankid_collect(order_ref);
    }
  },

  methods: {
    async simple_bankid_collect(order_ref) {
      try {
        const res = await axios.get(`/bankid/simple/collectstatus?intent=memberlogin&order_ref=${order_ref}`);

        if (this.debug) {
          console.log('simple_bankid_collect', res.status, res.data);
          return;
        }

        if (res.status === 200) {

          if (res.data.status === 'authenticated') {
            console.log('success authentication');

            if (this.debug) {
              console.log('success data', res.data);
              return;
            }

            this.$store.dispatch(MEMBER_LOGIN, res.data);

            if (this.redirect) {
              this.$router.push({ name: this.redirect });
            }
            else {
              this.$router.push({ name: 'profile' });
            }
          }
          else if (res.data.status === 'no_access') {
            console.log('no_access', res.data);
            this.show_no_permissions = true;
          }
          else {
            console.log('not authenticated', res.data);
          }
        }
        else if (res.status === 404) {
          this.show_no_permissions = true;
        }
      }
      catch (err) {
        console.error('simple collect exception', err);
      }
    },

  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
